export const getTasks = async (task_ids) => {
    const url = getUrl('/api/tasks/') + '?ids=' + task_ids.join(',') + `&ts=${(new Date()).getTime()}`;
    const response = await logFetchAJAX(url, {});
    let task_dict = response['tasks'];
    let result = [];
    task_ids.forEach((e) => { 
        let task = task_dict[e] || null
        if (task != null) result.push(task);
    });
    return result;
}
